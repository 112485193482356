.home-promo-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    background-image: url("../../../../public/banner-home-img3.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 112px 0;
    position: relative;
    height: fit-content;

    @media (max-width: 1024px) {
        padding: 45px 0;
    }

    // @media (max-width: 767px) {
    //     padding: 28px 0;
    // }

}

.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(255, 255, 255, 0) 100%);

}

.home-promo {
    max-width: 1280px;
    width: 100%;
    padding: 0 21px;
    z-index: 22;

    @media screen and (min-width: 1025px) and (max-width: 1450px) {
        padding: 0 41px;
    }

    .content {
        max-width: 700px;
    }

    .title {
        margin-bottom: 20px;
        color: rgb(255, 255, 255);
        font-size: 69px;
        font-weight: 700;
        letter-spacing: -1px;
        line-height: 69px;
        font-family: Raleway, sans-serif;

        @media (max-width: 1024px) {
            font-size: 49px;
            line-height: 49px;
        }

        @media (max-width: 767px) {
            font-size: 39px;
            line-height: 39px;
        }
    }

    .text {
        color: #fff;
        margin-bottom: 34px;
        font-size: 16px;
        line-height: 24px;
        font-family: Inter, sans-serif;

        @media (max-width: 1024px) {
            font-size: 15px;
            line-height: 22px;
        }

        @media (max-width: 767px) {
            font-size: 14px;
            line-height: 21px;
        }
    }

}