body {
  /* font-family: 'Inter', sans-serif; */
font-family: 'Raleway', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: rgb(255, 255, 255);
  
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1{
  font-size: 69px;
  color: #fff;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
  line-height: 69px;
  letter-spacing: -1px;
}

@media (max-width: 1024px) {
  h1 {
    font-size: 49px;
    line-height: 49px;
  }
}

@media (max-width: 766px) {
  h1 {
    font-size: 39px;
    line-height: 39px;
  }
}

li{
  
  list-style: none;
}
a{
  text-decoration: none;
}
.nav-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #036eb6;
  padding: 15px 30px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 12px;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  >* {
      color: #fff;
      text-transform: uppercase;
  }

  cursor: pointer;
}

.nav-btn:hover {
  /* background-color: rgba(186, 54, 52, 0.918); */
  background-color: rgb(0, 70, 117);
  transition: all 0.3s ease-in;
}