.card {
    width: 407px;
    // height: 532px;
    border-bottom: 4.8px solid rgb(220, 220, 222);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 60px 0px;
    margin: 10px;
    background-color: #fff;
    @media (max-width: 1300px) {
        //height: fit-content;
    }
    
    @media (max-width: 767px) {
        max-width: 500px;
        width: calc(100% - 42px);
    }
 
    .icon {
        width: 42px;
        height: 42px;
        margin-right: 21px;

        @media (max-width: 1024px) {
            width: 35px;
            height: 35px;
            margin: 0;
            margin-bottom: 14px;
        }

        @media (max-width: 767px) {
            margin: 0;
            margin-right: 14px;
        }
    }

    .img-wrapper {
        width: 100%;
       
    }

    .img {
         //max-height: 250px;
        // @media (max-width: 1024px) {
        //     max-height: 150px;
        // }
        // height: fit-content;
        width: 100%;
        object-fit: cover;
    }
    .bottom {
        .text {
           // padding: 21px;
           padding: 10px;
            padding-bottom: 14px;
            margin-bottom: 14px;
            min-height: 87px;
            @media (max-width: 1200px) {
                min-height: 108px;
            }
            @media (max-width: 840px) {
                min-height: 119px;
            }
            @media (max-width: 767px) {
                min-height: auto;
            }
        }
    }

    .top {
        padding: 21px 10px;
        display: flex;
        // @media screen and (min-width: 768px) and (max-width: 1024px) {
        //     min-height: 198px;
        // }
        @media screen and (min-width: 768px) and (max-width: 1070px) {
            min-height: 92px;
        }
        .text-wrapper {
            .title {
                color: rgb(34, 35, 52);
                font-family: Raleway, sans-serif;
                font-weight: 700;
                letter-spacing: -0.5;
                line-height: 28px;
                font-size: 20px;
                @media (max-width: 1024px) {
                    font-size: 18px;
                    line-height: 25px;
                }

                @media (max-width: 767px) {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }

        @media (max-width: 1024px) {
            flex-direction: column;
            align-items: center;
            .text-wrapper {
                width: 100%;
                > * {
                    text-align: left;
                }
            }
        }
        @media (max-width: 767px) {
            flex-direction: row;
            align-items: flex-start;
            .text-wrapper {
                > * {
                    text-align: left;
                }
            }
        }
    }

    .nav-btn {
        width: 137px;
        font-size: 12px;
        padding: 12px 24px;
        margin-left: 10px !important;
        margin-bottom: 21px !important;
    }

    .text {
        color: rgb(97, 99, 121);
        font-family: Inter, sans-serif;
        font-weight: 400;
        line-height: 21px;
        font-size: 16px;
        @media (max-width: 1024px) {
            font-size: 14px;
            line-height: 19px;
        }

        @media (max-width: 767px) {
            font-size: 12px;
            line-height: 18px;
        }
    }
}

.card:hover {
    border-bottom: 4.8px solid #036eb6;
}

.service-product {
    .bottom {
        .text {
            min-height: auto !important;
        }
    }
}