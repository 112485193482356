.client-logo-container {
  width: 100%;
  padding: 0 21px 75px 21px;
  //margin: 20px 0;
  @media screen and (min-width: 1025px) and (max-width: 1450px) {
    padding: 0 41px 75px 41px;
  }
  @media (max-width: 1024px) {
    padding-top: 30px;
  }
}

.client-logo-img {
  cursor: pointer;
}
.client-logos {
  display: flex;
  max-width: 1280px;
  @media (max-width: 1400px) {
    max-width: 1120px;
  }
  width: 100%;
  margin: auto;
  gap: 20px;
  row-gap: 0;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.client-logo-wrapper {
  width: 25%;
  max-width: 150px;
  max-height: 50px;
  display: flex;
  align-items: center;
}

.client-logo-wrapper:last-child{
  display: flex;
  justify-content: flex-end;
}
.client-logo-img {
  width: 100%;
}
.zte img {
  width: 100px;
  height: 20px;
}
.client-content {
  margin-bottom: 30px;
}
.client-headline {
  margin-bottom: 20px;
  color: rgb(34, 35, 52);
  text-align: center;
  font-size: 39px;
  font-weight: 700;
  letter-spacing: -1.5px;
  margin: auto;
  padding-bottom: 15px;
  @media (max-width: 1024px) {
    font-size: 31px;
    line-height: 37px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
  }
}
.client-text {
  font-size: 18px;
  color: black;
  width: 60%;
  font-weight: 500;
  text-align: center;
  margin: auto;
  line-height: 28px;
  @media (max-width: 768px) {
    width: 80%;
    font-size: 16px;
  }
  @media (max-width: 510px) {
    width: 100%;
    font-size: 16px;
  }
}
