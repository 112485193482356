.banner-common {
    width: 100%;
    height: 400px;
    @media (max-width: 1024px) {
      height: 330px;
    }
    @media (max-width: 768px) {
      height: 230px;
    }
    @media (max-width: 566px) {
      height: 250px;
    }
    position: relative;
    //margin-top: 70px;
    // @media (max-width: 991px) {
    //   margin-top: 60px;
    // }
  }
  
  
  .banner-overlay {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.7539390756302521) 0%,
      rgba(0, 0, 0, 0.7035189075630253) 16%,
      rgba(0, 0, 0, 0.5018382352941176) 35%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  
  .banner-title {
    position: absolute;
    width: 100%;
    margin: auto;
    top: 30%;
    left: 0;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.9);
    padding: 0 21px;
    @media screen and (min-width: 1025px) and (max-width: 1450px) {
      padding: 0 41px;
  }
  }
  
  .banner-title-span {
    align-self: center;
    text-transform: uppercase;
    font-size: 48px;
    margin-bottom: 10px;
    @media (max-width: 1024px) {
        font-size: 38px;
    }
    @media (max-width: 768px) {
        font-size: 28px;
    }
  }
  .banner-text-span{
    font-weight: 400;
    font-size: 20px;
    width: 50%;
    @media (max-width: 1024px) {
        font-size: 18px;
        width: 60%;
    }
    @media (max-width: 768px) {
        font-size: 16px;
        width: 70%;
    }
    @media (max-width: 550px) {
        font-size: 14px;
        width: 90%;
    }
  }
  .banner-header{
    max-width: 1280px;
    width: 100%;
    margin: auto;
   // padding: 0 21px;
   
  }

  .slider-container {
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  // Dots

  .all-dots {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    top: 90%;
    justify-content: center;
    z-index: 900;
  }
  
  .dot {
    cursor: pointer;
    height: 7px;
    width: 7px;
    margin: 0px 3px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: inline-block;
  }
  
  .slider-container .dot:hover {
    background-color: rgba(0, 0, 0, 1);
  }
  
   .active-dot {
      background-color: #036eb6;;
    } 
  
  .slider-container .dot {
    background-color: rgba(255, 255, 255, 1);
  }
  .slider-container .active-dot {
    background-color: #036eb6;
  }