.why-us-wrapper {
    background-color: #222334;
   // background-image: url("../../../images/why-us-bg.png");
    background-position: center center;
    background-repeat: no-repeat;
    padding: 112px 0;
    @media (max-width: 1024px) {
        padding-top: 15px;
        padding-bottom: 45px;
    }
    @media (max-width: 767px) {
        padding-top: 0;
        padding-bottom: 28px;
    }
    > * {
        color: #fff;
    }
    display: flex;
    justify-content: center;
    .why-us {
        max-width: 1280px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .title1, .title2, .box-title {
        font-family: Raleway, sans-serif;
    }
    .text, .box-text {
        font-family: Inter, sans-serif;
    }
    .icon {
        width: 71px;
        height: 71px;
        margin-right: 21px;
        @media (max-width: 1024px) {
            width: 57px;
            height: 57px;
        }
        @media (max-width: 767px) {
            width: 43px;
            height: 43px;
        }
    }
    .title1 {
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 24px;
        margin-bottom: 20px;
        text-align: center;
        @media (max-width: 1024px) {
            font-size: 13px;
            line-height: 22px;
        }
        @media (max-width: 767px) {
            font-size: 12px;
            line-height: 21px;
        }
    }
    .title2 {
        font-size: 39px;
        font-weight: 700;
        letter-spacing: -1.5px;
        line-height: 47px;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        @media (max-width: 1024px) {
            font-size: 31px;
            line-height: 37px;
        }
        @media (max-width: 767px) {
            font-size: 25px;
            line-height: 30px;
        }
    }
    .box-title {
        margin-bottom: 7px;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.5px;
        line-height: 28px;
        @media (max-width: 1024px) {
            font-size: 18px;
            line-height: 25px;
            text-align: center;
        }
        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
            text-align: left;
        }
    }
    .text {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 34px;
        text-align: center;
        @media (max-width: 1024px) {
            font-size: 15px;
            line-height: 22px;
        }
        @media (max-width: 767px) {
            font-size: 14px;
            line-height: 21px;
        }
    }
    .box-text {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 7px;
        @media (max-width: 1024px) {
            font-size: 13px;
            line-height: 19px;
            text-align: center;
        }
        @media (max-width: 767px) {
            font-size: 12px;
            line-height: 18px;
            text-align: left;
        }
    }
    .top {
        max-width: 656px;
        width: 100%;
        padding: 32px;
        @media (max-width: 1024px) {
            max-width: 660px;
            padding: 30px;
        }
        @media (max-width: 767px) {
            max-width: 664px;
            padding: 21px;
        }
    }
    .bottom {
        width: 100%;
    }
    .box {
        display: flex;
        max-width: 726px;
        width: 100%;
        padding: 32px;
        @media screen and (min-width: 1025px) and (max-width: 1450px) {
            padding: 32px 41px;
        }
        @media (max-width: 1024px) {
            padding: 30px;
            .icon {
                margin: 0;
                margin-bottom: 14px;
            }
            flex-direction: column;
            align-items: center;
        }
        @media (max-width: 767px) {
            padding: 21px;
            max-width: none;
            flex-direction: row;
            align-items: flex-start;
            .icon {
                margin: 0;
                margin-right: 14px;
            }
        }
    }

    .row {
        display: flex;
        width: 100%;
        @media (max-width: 767px) {
            flex-direction: column;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 767px) {
            align-items: flex-start;
        }
    }
}