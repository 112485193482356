.news-detail-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 21px;
    @media screen and (min-width: 1025px) and (max-width: 1450px) {
        padding: 0 41px;
    }
    @media (max-width: 350px) {
        padding: 0;
    }
}

.news-detail {
    max-width: 1280px;
    width: 100%;
    padding: 100px 0;
    display: flex;
    @media (max-width: 1024px) {
        flex-direction: column-reverse;
        align-items: center;
        padding: 80px 0;
        padding-bottom: 0;
    }
    @media (max-width: 767px) {
        padding-top: 40px;
    }
    .news-detail-left {
        max-width: 384px;
        width: 100%;
       
        @media (max-width: 1024px) {
            max-width: none;
        }
       .top {
        padding: 32px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 60px 0px;
        @media (max-width: 1024px) {
            padding: 30px;
            max-width: none;
        }
        @media (max-width: 1024px) {
            padding: 30px;
            max-width: none;
            margin-top: 30px ;
        }
        @media (max-width: 767px) {
            padding: 28px;
        }
       }
        
        .row-wrapper {
            @media (max-width: 1024px) {
                display: flex;
            }
            @media (max-width: 767px) {
                flex-direction: column;
            }
        }
        .title {
            margin-bottom: 20px;
            color: rgb(34, 35, 52);
            font-family: Raleway, sans-serif;
            font-size: 25px;
            letter-spacing: -0.5px;
            line-height: 32.5px;
            @media (max-width: 1024px) {
                font-size: 21px;
                line-height: 27.5px;
            }
            @media (max-width: 767px) {
                font-size: 20px;
                line-height: 26px;
            }
        }
        .news-detail-card {
            min-height: 80px;
            width: 100%;
            display: flex;
            cursor: pointer;
            margin-bottom: 14px;
            gap: 10px;
            .content {
                //min-width: 150px;
                width: 70%;
            }
            .news-detail-imgs{
                width:30%
            }
            .news-detail-imgs img{
                width: 100%;
            }
            .headline {
                width: 100%;
               color: rgb(97, 99, 121);
               font-size: 14px;
               font-family: Inter, sans-serif;
               line-height: 21px;  
               @media (max-width: 1024px) {
                font-size: 13px;
                line-height: 19px;
               }
               @media (max-width: 767px) {
                font-size: 12px;
                line-height: 18px;
               }
            }
            .date {
                width: 100%;
                color: rgb(173, 173, 173);
                font-size: 13px;
                font-family: Inter, sans-serif;
                line-height: 24px;
                margin-bottom: 13px;
                @media (max-width: 1024px) {
                    font-size: 12px;
                    line-height: 22px;
                   }
                   @media (max-width: 767px) {
                    font-size: 11px;
                    line-height: 21px;
                   }
            }
            > img {
                max-width: 80px;
                max-height: 80px;
                width: 100%;
                height: 100%;
                object-fit: contain;
                margin-right: 16px;
                @media (max-width: 1024px) {
                    max-width: 113px;
                    max-height: 113px;
                }
                @media (max-width: 767px) {
                    max-width: 177px;
                    max-height: 177px;
                    min-height: 80px;
                    min-width: 80px;
                    height: auto;
                    width: auto;
                }
            }
        }
        .news-detail-card-active {
            .headline, .date {
                color:rgb(3, 110, 182);
            }
            .headline {
                font-weight: 600;
            }
        }
    }

    .news-detail-right {
        max-width: 896px;
        width: 100%;
        margin-left: 40px;
        @media (max-width: 1024px) {
            margin-left: 0;
        }
         @media (max-width: 350px) {
        padding: 0 21px !important;
       }
    .headline {
        margin-bottom: 20px;
            color: rgb(34, 35, 52);
            font-family: Raleway, sans-serif;
            font-size: 39px;
            font-weight: 700;
            letter-spacing: -1.5px;
            line-height: 47px;
            @media (max-width: 1024px) {
                font-size: 31px;
                line-height: 37px;
            }
            @media (max-width: 767px) {
                font-size: 25px;
                line-height: 30px;
            }
    }
    .text {
        margin-bottom: 14px;
            color: rgb(97, 99, 121);
            font-family:  Inter, sans-serif;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 20px;
            text-align: justify;
            @media(max-width: 1024px) {
                font-size: 15px;
                line-height: 22px;
            }
            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 21px;
            }
    }
    .date {
        width: 100%;
                color: rgb(173, 173, 173);
                font-size: 13px;
                font-family: Inter, sans-serif;
                line-height: 24px;
                margin-bottom: 13px;
                @media (max-width: 1024px) {
                    font-size: 12px;
                    line-height: 22px;
                   }
                   @media (max-width: 767px) {
                    font-size: 11px;
                    line-height: 21px;
                   }
    }
   
    .slide-wrapper {
       
        width: 100%;
        > img {
            width: 100%;
        }
    }

    .slide-image-wrapper { 
        margin: 32px 0px;
        @media (max-width: 1024px) {
            margin-top: 30px;
        }
        @media (max-width: 767px) {
            margin-top: 28px;
        }
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        .slide-image {
            width: 100%;
            max-height: 500px;
            height: 100%;
            object-fit: cover;
            object-position: center;
            display: none;
        }
    }
}
.left-right-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 0;
    .prev, .next {
        cursor: pointer;
    }
}

.slide-image-active {
    display: block !important;
}

}