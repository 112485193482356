.contact-comp-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-comp {
    max-width: 1280px;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 100px;
    @media (max-width: 1024px) {
        padding-top: 15px;
        padding-bottom: 45px;
    }
    @media (max-width: 767px) {
        padding-top: 0;
        padding-bottom: 28px;
    }


.title {
    color: rgb(34, 35, 52);
    font-family: Raleway, sans-serif;
    font-size: 20px;
    letter-spacing: -0.5px;
    line-height: 28px;
    font-weight: 700;
    @media (max-width: 1024px) {
        line-height: 25px;
        font-size: 25px;
    }
    @media (max-width: 767px) {
        line-height: 16px;
        font-size: 22px;
    }
    margin-bottom: 7px;
}

.text {
    color: rgb(97, 99, 121);
    font-family: Inter, sans-serif;
    font-size: 16px;
line-height: 24px;

@media (max-width: 1024px) {
    font-size: 15px;
    line-height: 22px;
}

@media (max-width: 767px) {
    font-size: 14px;
    line-height: 21px;
}
}
}
.contact-detail {
    padding: 0 21px;

    @media screen and (min-width: 1025px) and (max-width: 1450px) {
        padding: 0 41px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .top {
        max-width: 720px;
        width: 100%;
        > * {
            text-align: center;
        }
        padding: 32px;
        @media (max-width: 1024px) {
            padding: 30px;
        }
        @media (max-width: 767px) {
            padding: 28px;
        }
    }
    .bottom {
        display: flex;
        width: 100%;
        @media (max-width: 767px) {
            flex-direction: column;
        }
        .box {
            max-width: 426px;
            width: 100%;
            height: 277px;
            background-color: #fff;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 60px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 10px;
            padding: 48px;
            @media (max-width: 1024px) {
                padding: 30px 15px;
            }
            @media (max-width: 767px) {
                padding: 28px;
                max-width: none;
                margin: 10px 0;
            }
            > * {
                text-align: center;
            }

            .icon {
                width: 56px;
                height: 56px;
                margin-bottom: 14px;
            }
        }
    }
    .title1 {
        color: #036eb6;
        margin-bottom: 20px;
        line-height: 24px;
        font-weight: 600;

        @media (max-width: 1024px) {
            font-size: 15px;
            line-height: 22px;
        }

        @media (max-width: 767px) {
            font-size: 14px;
            line-height: 21px;
        }
    }

    .title2 {
        margin-bottom: 20px;
        color: rgb(34, 35, 52);
        font-size: 39px;
        font-weight: 700;
        letter-spacing: -1.5;
        line-height: 47px;
        font-family: Raleway, sans-serif;

        @media (max-width: 1024px) {
            font-size: 31px;
            line-height: 37px;
        }

        @media (max-width: 767px) {
            font-size: 25px;
            line-height: 30px;
        }
    }

}

.contact-form {
    width: 100%;
    padding: 112px 0;
    .container-wrapper {
        max-width: 1280px;
        padding: 21px;
        @media screen and (min-width: 1025px) and (max-width: 1450px) {
            padding: 0 41px;
        }
        @media (max-width: 1024px) {
            padding: 45px 21px;
        }
        @media (max-width: 767px) {
            padding: 28px 21px;
        }
    }
    .container {
       
        display: flex;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 60px 0px;
        width: 100%; 
        
        @media (max-width: 767px) {
            flex-direction: column;
        }
    }
    .left {
        // max-width: 630px;
        // max-height: 600px;
        width: 50%;
        overflow: hidden;
        @media (max-width: 767px) {
            max-height: 375px;
            max-width: none;
            // padding: 10px;
            width: 100%;
        }
        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: right top;
            @media (max-width: 767px) {
                object-position: center center;

            }
        }
    }
    .right {
        // max-width: 630px;
        // max-height: 600px;
        padding: 64px;
        width: 50%;
        @media (max-width: 1024px) {
            padding: 45px;
        }
        @media (max-width: 767px) {
            padding: 28px;
            max-width: none;
            width: 100%;
            
        }
        .title {
            margin-bottom: 20px;
            font-size: 39px;
            font-size: 39px;
            letter-spacing: -1.5;
            line-height: 47px;
            @media (max-width: 1024px) {
                font-size: 31px;
                line-height: 37px;
            }
            @media (max-width: 767px) {
                font-size: 25px;
                line-height: 30px;
            }
        }
        .text {
            margin-bottom: 34px;
        }
    }
    .form {
        #name, #email {
            height: 47px;
            width: 100%;
        }
        #message {
            height: 98px;
            width: 100%;
        }
        #name, #email, #message {
            background-color: rgb(245, 244, 245);
            border: 0;
            padding: 6px 16px;
            margin-bottom: 10px;
        }
        .nav-btn {
            max-width: 167px;
        }

        .form-label {
            font-family: "Be Vietnam Pro", sans-serif;
            font-size: 16px;
            line-height: 24px;
            color: rgb(97, 99, 121);
            margin-bottom: 7px;
            @media (max-width: 1024px) {
                font-size: 15px;
                line-height: 22px;
            }
            
            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 21px;
            }
        }
       
    }
}

.contact-map {

    //padding: 0 21px;

    @media screen and (min-width: 1025px) and (max-width: 1450px) {
        padding: 0 41px;
    }
    width: 100% ;
    height: 500px;
    @media (max-width: 1024px) {
        height: 400px;
    }
    @media (max-width: 767px) {
        height: 300px;
    }
    > * {
        width: 100% !important;;
        height: 100%;
        border: 0 !important;
    }
}