.home-key-fig-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 112px 0;
    @media (max-width: 1024px) {
        padding: 45px 0;
    }
    @media (max-width: 767px) {
        padding: 0;
    }
    background-image: url("../../../images/key-fig-bg.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
// .top-title-intro{
//     color: #ba3436;
//     margin-bottom: 20px;
//     line-height: 24px;
//     font-weight: 600;
//     @media (max-width: 1024px) {
//         font-size: 15px;
//         line-height: 22px;
//     }
//     @media (max-width: 767px) {
//         font-size: 14px;
//         line-height: 21px;
//     }
// }
.home-key-fig {
    max-width: 1280px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 21px;
    @media screen and (min-width: 1025px) and (max-width: 1450px) {
        padding: 0 41px;
    }

    .home-key-fig-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        > * {
            font-family: Raleway, sans-serif;
        }
        width: 100%;
        max-width: 840px;
        @media (max-width: 1024px) {
            max-width: 720px;
        }
    }

    .key-fig-bottom-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        max-width: 798px;
        @media (max-width: 1024px) {
            max-width: 678px;
        }
       .key-fig-row {
        width: 66.6%;
       }
       @media (max-width: 767px) {
        flex-direction: column;
        .key-fig-row {
            width: 100%;
        }
       }
    }
    .top-number {
        color: #036eb6;
        font-size: 69px;
        font-weight: 700;
        letter-spacing: -1px;
        line-height: 69px;
        margin-bottom: 20px;
        text-align: center;
        > span {
            font-size: 16px;
        }
        @media (max-width: 1024px) {
            font-size: 49px;
        }
        @media (max-width: 767px) {
            font-size: 39px;
        }
        @media (max-width: 450px) {
            font-size: 32px;
        }
    }
    .top-title {
        font-size: 39px;
        color: rgb(34, 35, 52);
        font-weight: 700;
        letter-spacing: -1.5px;
        line-height: 47px;
        margin-bottom: 20px;
        text-align: center;
        @media (max-width: 1024px) {
            line-height: 37px;
            font-size: 31px;
        }
        @media (max-width: 767px) {
            line-height: 30px;
            font-size: 25px;
        }
    }
    .key-fig-text {
        color: rgb(97, 99, 121);
        font-size: 16px;
        font-family: Inter, sans-serif;
        line-height: 24px;
        margin-bottom: 34px;
        text-align: center;
        @media (max-width: 1024px) {
            font-size: 15px;
            line-height: 22px;
        }
        @media (max-width: 767px) {
            font-size: 14px;
            line-height: 21px;
        }
    }
    .bottom-text {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.5;
        line-height: 28px;
        text-align: center;
        color: rgb(34, 35, 52);
        @media (max-width: 1024px) {
            font-size: 18px;
            line-height: 25px;
        }
        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
        }
    }
    .key-fig-bottom {
        .top-number {
            margin: 0;
        }
        .unit {
            font-size: 49px;
            @media (max-width: 1024px) {
                font-size: 39px;
            }
            @media (max-width: 767px) {
                font-size: 29px;
            }
            @media (max-width: 450px) {
                font-size: 20px;
            }
        }
    }
    .key-fig-row {
        display: flex;
        justify-content: space-between;
    }

}
