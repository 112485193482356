.call-to-action{
    width: 100%;
    padding: 0 21px;
    background-color: #222334;
    @media screen and (min-width: 1025px) and (max-width: 1450px) {
        padding: 0 41px;
    }
}
.cta-wrapper{
    max-width: 1280px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 0;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        gap: 25px;
    }
}
.cta-intro{
    color: white;
    @media (max-width: 768px) {
        text-align: center;
        width: 100%;
    }
}
.cta-intro h6{
    font-size: 14px;
    //margin-bottom: 20px;
    @media (max-width: 768px) {
        font-size: 12px;
    }
}

.cta-intro h4{
    font-size: 22px;
    font-weight: 400;
    width: 80%;
    @media (max-width: 768px) {
        width: 100%;
    }
    @media (max-width: 1024px) {
        font-size: 18px;
    }
}

/* CTA BTN*/

.cta-btn-new{
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: white;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 35px;
    white-space: nowrap;
    @media (max-width: 1024px) {
        padding: 7px 25px;
    }
}
.cta-btn-new:hover{
    background-color: #0a0b14;
    border: 1px solid #0a0b14;
    transition: all 0.3s ease-in;
}
.cta-btn-text{
    display: flex;
    align-items: center;
    gap: 5px;
}
.cta-btn-text:last-child{
    padding-top: 2px;
}