.navbar-top-wrapper {
    background-color: #036eb6;
    width: 100%;
    height: 41px;
    display: flex;
    justify-content: center;
    padding: 0 21px;
    @media screen and (min-width: 1025px) and (max-width: 1450px) {
        padding: 0 41px;
    }
}

.fixed-navbar {
    position: fixed;
    top: 0;
    left: 0;
    .navbar-top-wrapper {
        display: none;
    }
    z-index: 444;
    width: 100vw;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 60px 0px;
    margin-top: -2px;
}

.navbar-top {
    max-width: 1280px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    .nav-right {
        display: flex;
        column-gap: 20px;

        @media (max-width: 800px) {
            width: 100%;
            justify-content: space-between;
        }
    }
}

.nav-location,
.nav-email,
.nav-phone,
.nav-lang,
.nav-lang-dropdown {
    display: flex;
    align-items: center;
    height: 100%;
    font-family: 'Inter', sans-serif;

    >* {
        color: #fff;
    }

    .nav-icon {
        width: 12px;
        height: 16px;

    }

    .nav-text {
        font-size: 13px;
        padding-left: 10px;
    }
}

.nav-location {
    @media (max-width: 800px) {
        display: none;
    }
}

.nav-email {
    @media (max-width: 450px) {
        display: none;
    }
}

.nav-lang-wrapper {
    position: relative;
    padding: 0 10px;
    cursor: pointer;

    .nav-lang-dropdown-wrapper {
        position: absolute;
        left: 0;
        top: 35px;
        width: 100%;
        background-color: rgb(3, 110, 182);
     
        z-index: 33;
        box-shadow: 0px  5px  10px rgba(0, 0, 0, 0.1);
        .nav-lang-dropdown {
            padding: 5px 0;
            width: 100%;
            padding: 5px 10px;
        }
    }

    .nav-lang-dropdown:hover {
      > * {
        color: rgba(255, 255, 255, 0.8);
      }
      background-color: #76000041;
    }
}
.nav-lang-wrapper:hover {
    .nav-lang {
        > * {
            color: rgba(255, 255, 255, 0.5) !important;
        }
    }
}

.navbar-bottom-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 76px;
    background-color: #fff;
    position: relative;

    @media (max-width: 1024px) {
        height: 62px;
    }

    padding: 0 21px;
    @media screen and (min-width: 1025px) and (max-width: 1450px) {
        padding: 0 41px;
    }
}

.navbar-bottom {
    max-width: 1280px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo-wrapper {
    max-width: 200px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px;
}

.logo {
    height: 100%;
    cursor: pointer;
}

.navlinks {
    display: flex;
    align-items: center;
    column-gap: 20px;
    height: 100%;
    
.nav-btn-wrapper {
    width: 100%;
    max-width: 172px;

    @media (max-width: 1024px) {
        max-width: 231px;
        display: none;
    }   
}
}

.navlink-wrapper {
    display: flex;

    @media (max-width: 1024px) {
        display: none;
    }

    >* {
        text-decoration: none !important;
    }

    .active {
        text-decoration: none;

        >* {
            color: rgba(34, 35, 52, 0.5) !important;
        }
    }

    li {
        color: rgb(34, 35, 52);
        list-style: none;
        padding: 29px 17px;
        cursor: pointer;
        font-family: 'Be Vietnam Pro', sans-serif;
        font-weight: 600;
        white-space: nowrap;
    }

    li:hover {
        // background-color: rgba(0, 0, 0, 0.03);
        color: rgba(34, 35, 52, 0.5);
    }
    @media (max-width: 1024px) {
        li:hover {
            background-color: #036eb6;
            color: #fff !important;
            transition: all 0.3s ease-in;
        }
    }
}


.nav-dropdown-wrapper {
    position: relative;

    width: max-content;
    .nav-link {
        display: flex;
        align-items: center;

        >:first-child {
            padding-right: 5px;
        }
    }

    .nav-dropdown {
        position: absolute;
        left: 0;
        top: 76px;
        width: max-content;
        z-index: 33;
        padding-left: -17px;
        background-color: #fff;
        >* {
            text-decoration: none !important;
        }

        li {
            height: 30px;
            display: flex;
            align-items: center;
            color: rgb(34, 35, 52);
            list-style: none;
            cursor: pointer;
            font-family: 'Be Vietnam Pro', sans-serif;
            font-weight: 600;
            border-bottom: 1px solid #ecebeb9f;
        }

        >:last-child li {
            border-bottom: 0;
        }

        li:hover {
            background-color: #036eb6;
            color: #fff !important;
            transition: all 0.3s ease-in;
        }
    }

    .nav-dropdown:last-child {
        border-bottom: 3px solid #036eb6;
    }


}

.nav-mobile-menu-wrapper {
    display: none;

    @media (max-width: 1024px) {
        display: block;
    }

    .nav-mobile-menu {
        >* {
            color: #036eb6;
            width: 28px;
            height: 28px;
        }
    }
}

.menu-open {
    @media (max-width: 1024px) {
        display: flex;
        position: absolute;
        left: 0;
        top: 60px;
        z-index: 44;
        background-color: #fff;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
        ;

        >a li,
        li {
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #f3efef;
        }

        >a,
        li {
            width: 100%;

        }

        >a li {
            padding-left: 0;
            padding-left: 21px;
        }

        .nav-dropdown-wrapper {
            padding: 0;
        }

        .nav-dropdown {
            position: relative;
            top: 0;
            width: 100%;
            margin: 0;
            padding-right: 0;

            li {
                height: 40px;
            }
        }

        .nav-link {
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 21px;
        }

        .nav-dropdown:last-child {
            border-bottom: 3px solid #036eb6;
            // padding-bottom: 15px;
        }

        .nav-dropdown a li {
            padding-left: 31px;
        }

        .nav-dropdown-wrapper:hover {
            .nav-link {
                background-color: #036eb6;
                color: #fff !important;
            }
        }

    }
}