.miss-vis-wrapper {
  background-image: url(../../images/mission-vision-bg.jpg);
  background-position: center;
  background-size: cover;
}
.miss-vis-container {
  width: 100%;
  //height: 900px;
}
// .container-vis-miss {
//   max-width: 1280px;
//   width: 100%;
//   display: flex;
//   margin: auto;
// }
.miss-vis-container {
  width: 100%;
  display: flex;
  @media (max-width:768px) {
    flex-direction: column;;
  }
}
.mission-left-bg {
  width: 50%;
  @media (max-width:768px) {
    width: 0;
  }
}
.mission-vision {
  width: 50%;
  @media (max-width:768px) {
    width: 100%;
  }
  background-color: rgba(0, 0, 0, 0.7);
}
.miss-viss-inner {
  max-width: 640px;
  width: 100%;
  padding: 100px 21px 100px 70px;
  @media (max-width: 1200px) {
    padding: 80px 21px 80px 40px;
  }
  @media screen and (min-width: 1025px) and (max-width: 1450px) {
    padding-right: 41px;
}
@media (max-width: 1024px) {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width:768px) {
    padding-left: 21px;
  }
}
.miss-vis-content {
  display: flex;
    flex-direction: column;
  
  gap: 80px;
  @media (max-width: 1024px) {
    gap: 50px;
  }
  h3{
    letter-spacing: 2px;
  }
}
.mission-vission-headline {
  color: white;
  h4 {
    margin-bottom: 20px;
    line-height: 24px;
    font-weight: 600;
    text-transform: uppercase;
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 21px;
    }
    
  }
  h2 {
    margin-bottom: 20px;
    font-size: 39px;
    font-weight: 700;
    letter-spacing: -1.5;
    line-height: 47px;
    font-family: Raleway, sans-serif;
    @media (max-width: 1024px) {
      font-size: 31px;
      line-height: 37px;
    }
    @media (max-width: 767px) {
      font-size: 25px;
      line-height: 30px;
    }
  }
  p{
    font-weight: 400;
    font-size: 16px;
    font-family: inter, sans-serif;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 21px;
    }
  }
}
.mission,
.vision {
  h3 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 10px;
    margin-top: 20px;
    @media (max-width: 1024px) {
      font-size: 22px;
      //line-height: 18px;
    }
    @media (max-width: 767px) {
      font-size: 20px;
      // line-height: 30px;
    }
  }
  p {
    font-weight: 400;
    font-size: 16px;
    font-family: inter, sans-serif;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 21px;
    }
    color: white;
  }
}
.mission-icon{
    border: 1px solid #008be7;
    border-radius: 50%;
   height: 45px;
   width: 45px;
   display: flex;
   align-items: center;
   justify-content: center;
   >*{
    width: 50%;
    height: 50%;
   }
}