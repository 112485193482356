.home-about-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    // padding: 0 21px;
    @media screen and (min-width: 1025px) and (max-width: 1450px) {
        padding: 0 41px;
    }
}

.home-about {
    display: flex;
    max-width: 1280px;
    width: 100%;
    justify-content:space-between;
    > div {
        width: 50%;
    }
    padding-top: 112px;
    @media (max-width: 1024px) {
        padding-top: 45px;
    }
    @media (max-width: 767px) {
        flex-direction: column;
        padding-top: 14px;
        > div {
            width: 100%;
        }
    }

    .home-about-left {
        padding: 48px 80px 48px 0px;
        font-family: Inter, sans-serif;

        @media (max-width: 1024px) {
            padding: 30px;
        }
        @media (max-width: 767px) {
            padding: 21px;
        }

        .top-title {
            color: #036eb6;
            margin-bottom: 20px;
            line-height: 24px;
            font-weight: 600;
            @media (max-width: 1024px) {
                font-size: 15px;
                line-height: 22px;
            }
            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 21px;
            }
        }

        .main-title {
            margin-bottom: 20px;
            color: rgb(34, 35, 52);
            font-size: 39px;
            font-weight: 700;
            letter-spacing: -1.5;
            line-height: 47px;
            font-family:  Raleway, sans-serif;
            @media (max-width: 1024px) {
                font-size: 31px;
                line-height: 37px;
            }
            @media (max-width: 767px) {
                font-size: 25px;
                line-height: 30px;
            }
        }

        .about-text {
            color: rgb(97, 99, 121);
            margin-bottom: 34px;
            font-size: 16px;
            line-height: 24px;
            @media (max-width: 1024px) {
                font-size: 15px;
                line-height: 22px;
            }
            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
    .home-about-right {
        padding: 10px 0;
        margin-bottom: 20px;
        @media (max-width: 1024px) {
            padding-right: 30px;
        }
        @media (max-width: 767px) {
            padding: 21px;
        }
        > img {
            width: 100%;
        }
    }
    .list-wrapper {
        display: none;
    }
}

.service-about {
    .nav-btn {
        display: none;
    }
    .list-wrapper {
        display: block;
        margin-top: -10px;
        .list {
            padding-left: 20px;
            padding-bottom: 10px;  
            color: rgb(97, 99, 121);  
            .icon {
                padding-right: 10px;
                color: #ba3436;
            }  
        }
    }

    .bottom {
        .home-about {
            flex-direction: row-reverse;
            .home-about-left {
                padding-right: 0;
                padding-left: 80px;
                @media (max-width: 1024px) {
                    padding: 30px;
                }
                @media (max-width: 767px) {
                    padding: 21px;
                }
            }
            .home-about-right {
                padding-right: 0;
                padding-left: 30px;
                @media (max-width: 1024px) {
                    padding-right: 30px;
                }
                @media (max-width: 767px) {
                    padding: 21px;
                }
            }
            @media (max-width: 767px) {
                flex-direction: column;
                padding-top: 14px;
                > div {
                    width: 100%;
                }
            }
        }
    }
}
