.mang-team-container {
  width: 100%;
  padding: 50px 21px;
  @media screen and (min-width: 1025px) and (max-width: 1450px) {
    padding: 30px 41px;
  }
}

.mang-team-wrapper {
  max-width: 1280px;
  width: 100%;
  margin: auto;
}

.team-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-content h4 {
  color: #036eb6;
  margin-bottom: 10px;
  line-height: 24px;
  font-weight: 600;
  @media (max-width: 1024px) {
    font-size: 15px;
    line-height: 22px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 21px;
  }
}

.team-content h2 {
  margin-bottom: 10px;
  color: rgb(34, 35, 52);
  font-size: 39px;
  font-weight: 700;
  letter-spacing: -1.5;
  line-height: 47px;
  font-family: Raleway, sans-serif;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 31px;
    line-height: 37px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
  }
}
.team-content p {
  font-size: 18px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: #424349;
  text-align: center;
  width: 60%;
  @media (max-width: 1024px) {
    font-size: 16px;
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
}
.mang-team-card {
  margin: 60px 0;
}
.cards-row-1-1,
.cards-row-2-2 {
  display: flex;
  width: 50%;
  gap: 20px;
  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
}
.cards-row-1,
.cards-row-2 {
  width: 100%;
  display: flex;
  @media (max-width: 550px) {
    flex-direction: column;
    width: 100%;
  }
  gap: 20px;
}
.cards-row-2 {
  padding-top: 20px;
}
.team-card {
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
  }
}
.team-img-wrapper {
  width: 100%;
}
.team-card img {
  width: 100%;
}
.team-intro h3 {
  color: #036eb6;
  font-weight: 700;
  font-size: 20px;
  @media (max-width: 1024px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
  margin: 10px 0;
  //height: 65px;
  @media (max-width: 550px) {
    height: 100%;
    margin-bottom: 10px;
  }
  display: none;
}
.team-intro h5 {
  font-weight: 500;
  color: #616379;
  text-transform: uppercase;
  @media (max-width: 767px) {
    font-size: 12px;
  }
 
}
// Row - Middle

.row-mid{
  display: flex;
  width: 100%;
   gap: 20px;
   padding-top: 20px;
   @media (max-width:767px){
    flex-direction: column;
   }
}
.row-mid-1, .row-mid-2{
 width: 50%;
 @media (max-width:767px){
  width: 100%;
 }
}
.row-mid-1 img{
  width: 100%;
}
 .row-mid-2 img{
  width: 100%;
}