.home-product-wrapper {
  display: flex;
  justify-content: center;
  background-image: url("../../../images/home-product-bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.home-product {
  max-width: 1280px;
  width: 100%;
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
  .product-top-wrapper {
    display: flex;
    justify-content: center;
  }

  .product-top {
    max-width: 720px;
    width: 100%;
    padding: 32px;
    @media screen and (min-width: 1025px) and (max-width: 1450px) {
      padding: 32px 41px;
    }
    @media (max-width: 1024px) {
      padding: 30px;
    }
    @media (max-width: 767px) {
      padding: 28px;
    }
    .title {
      margin-bottom: 20px;
      color: rgb(34, 35, 52);
      text-align: center;
      font-family: Raleway, sans-serif;
      font-size: 39px;
      font-weight: 700;
      letter-spacing: -1.5px;
      line-height: 47px;
      @media (max-width: 1024px) {
        font-size: 31px;
        line-height: 37px;
      }
      @media (max-width: 767px) {
        font-size: 25px;
        line-height: 30px;
      }
    }
    .text {
      margin-bottom: 14px;
      color: rgb(97, 99, 121);
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 16px;
      line-height: 24px;
      @media (max-width: 1024px) {
        font-size: 15px;
        line-height: 22px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  .card-wrapper {
    width: 100%;
    padding-bottom: 112px;
    @media (max-width: 1024px) {
      padding-bottom: 45px;
    }
    @media (max-width: 767px) {
      padding-bottom: 28px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1450px) {
      padding: 0 41px 112px 41px;
    }
  }
  .row {
    width: 100%;
    display: flex;
    // gap: 20px;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      padding: 0 21px;
    }
  }
}
.row-1 .card .top {
  @media screen and (min-width: 768px) and (max-width: 890px) {
    min-height: 92px;
  }
}

.service-product {
  .nav-btn {
    display: none;
  }
}

.row3 {
  > div {
    opacity: 0;
  }
  >div:first-child {
    opacity: 1;
  }
  @media (max-width: 767px) {
    > div {
      display: none;
    }
    > div:first-child {
      display: block;
    }
  }
}
