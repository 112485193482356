.about-wrapper {
  width: 100%;
  background-color: #f5f4f5;
  padding: 70px 21px;
  @media screen and (min-width: 1025px) and (max-width: 1450px) {
    padding: 70px 41px;
  }
  @media (max-width: 550px) {
    padding: 40px 21px;
  }
}
.about-main-container {
  //background-color: #c5c4c3;
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
  max-width: 1280px;
  width: 100%;
  margin: auto;
  gap: 58px;
}

/* Left Side */
.about-woda-intro {
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
  }
}
.about-woda-content {
  .intro-title {
    color: #036eb6;
    margin-bottom: 20px;
    line-height: 24px;
    font-weight: 600;
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 21px;
    }
  }
  .intro-main-title {
    margin-bottom: 20px;
    color: rgb(34, 35, 52);
    font-size: 39px;
    font-weight: 700;
    letter-spacing: -1.5;
    line-height: 47px;
    font-family: Raleway, sans-serif;
    @media (max-width: 1024px) {
      font-size: 31px;
      line-height: 37px;
    }
    @media (max-width: 767px) {
      font-size: 25px;
      line-height: 30px;
    }
  }
  .about-woda-text {
    color: rgb(97, 99, 121);
    margin-bottom: 34px;
    font-size: 18px;
    line-height: 24px;
    font-family: Inter, sans-serif;
    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

/* Right Side*/
.about-partners {
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
  }
}
.partner-link {
  text-decoration: underline 1px;
  text-underline-offset: 2px;
  text-decoration-color: #036eb6;
 line-height: 40px;
  margin-top: 10px;
}
.partner-link a{
   color: #036eb6;
   cursor: pointer;
   font-weight: 400;
}
.partner-link a:hover{
  color: #BA3634;
  transition: all .2s ease-out;
}
.woda-logo-abt{
  cursor: pointer;
}
/* Images*/
.about-woda-img {
  width: 100%;
  margin: auto;
}
.about-woda-img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

/*Partner Cards*/

.partner-card-1 {
  background-color: white;
  padding: 32px 10px 32px 32px;
  @media (max-width: 600px) {
    padding: 32px 10px 32px 10px;
  }
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    //justify-content: left;
  }
}
.card-intro {
  width: 80%;
  @media (max-width: 600px) {
    width: 100%;
  }
}
.partner-logo {
  width: 20%;
  @media (max-width: 600px) {
    width: 40%;
  }
}
.partner-logo-img {
  width: 100%;
}
.partner-logo-img img {
  width: 100%;
}

.partner-card-title {
  color: #222334;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 22px;
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
.partner-card-text {
  //text-align: justify;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: #616379;
  text-align: justify;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
  @media (max-width: 400px) {
    text-align: left;
  }
}

/* Development Plan*/

.dev-plan-wrapper {
  width: 100%;
  padding: 70px 21px;
  background-color: #f5f4f5;
  @media screen and (min-width: 1025px) and (max-width: 1450px) {
    padding: 70px 41px;
  }
  @media (max-width: 565px) {
    padding: 40px 21px;
  }
}
.dev-paln-container {
  max-width: 1280px;
  width: 100%;
  margin: auto;
}
.dev-plans-headline {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dev-intro h4 {
  color: #036eb6;
  margin-bottom: 20px;
  line-height: 24px;
  font-weight: 600;
  @media (max-width: 1024px) {
    font-size: 15px;
    line-height: 22px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 21px;
  }
}
.dev-title h2 {
  margin-bottom: 40px;
  color: rgb(34, 35, 52);
  font-size: 39px;
  font-weight: 700;
  letter-spacing: -1.5;
  line-height: 47px;
  font-family: Raleway, sans-serif;
  @media (max-width: 1024px) {
    font-size: 31px;
    line-height: 37px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
  }
  width: 70%;
  margin: auto;
  text-align: center;
  @media (max-width: 565px) {
    width: 100%;
  }
}
.dev-plan-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 20px;
  margin-top: 40px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}
.dev-plan-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  row-gap: 30px;
  @media (max-width: 550px) {
    width: 100%;
    row-gap: 10px;
    margin-bottom: 20px;
  }

}
.plan-box {
  padding: 12px;
  color: white;
  background-color: rgb(3, 110, 182);
  font-family: Raleway, sans-serif;
  font-weight: 500;
  font-size: 40px;
  box-shadow: 0px 0px 0px 13px rgba(3, 110, 182, 0.2);
  @media (max-width: 1024px) {
    padding: 8px;
    font-size: 30px;
  }
}
.plan-text {
  text-align: center;
  font-size: 18px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: #616379;
  width: 100%;
  margin-top: 20px;
  height: 130px;
  @media (max-width: 1024px) {
    font-size: 16px;
  }
  @media (max-width: 550px) {
    height: 100%;
    margin-bottom: 30px;
  }
}
.dev-plans-headline {
  margin-bottom: 70px;
}
//
// .dev-upper,
.dev-lower {
  display: flex;
  gap: 20px;
  width: 100%;
  // @media (max-width: 1024px) {
  //   width: 100%;
  //   gap: 50px;
  // }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

/* Social Impact*/

.social-impact-container {
  width: 100%;
  margin: 40px 0;
  padding: 0 21px;
  @media screen and (min-width: 1025px) and (max-width: 1450px) {
    padding: 0 41px;
  }
}
.social-impact-wrapper {
  max-width: 1280px;
  width: 100%;
  margin: auto;
}
.social-impact {
  margin: 0 0 60px 0;
}
.impact-title span {
  color: rgb(3, 110, 182);
}
.impact-title{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.impact-title h2 {
  margin-bottom: 20px;
  color: rgb(34, 35, 52);
  font-size: 39px;
  font-weight: 700;
  letter-spacing: -1.5;
  line-height: 47px;
  font-family: Raleway, sans-serif;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 31px;
    line-height: 37px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
  }
}

.impact-title P {
  text-align: center;
  font-size: 18px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: #616379;
  width: 100%;
  width: 50%;
  @media (max-width: 1024px) {
    font-size: 16px;
    width: 80%;
  }
  @media (max-width: 550px) {
    height: 100%;
    width: 100%;
  }
}

.impact-title h4 {
  color: rgb(3, 110, 182);
  margin-bottom: 20px;
  line-height: 24px;
  font-weight: 600;
  @media (max-width: 1024px) {
    font-size: 15px;
    line-height: 22px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 21px;
  }
}
.impact-wrapper {
  width: 100%;
  display: flex;
 gap: 30px;
  @media (max-width: 1100px) {
    flex-direction: column;
    gap: 40px;
  }
  @media (max-width: 550px) {
    flex-direction: column;
    gap: 35px;
  }
}
.impact-top, .impact-bottom{
  display: flex;
  @media (max-width: 550px) {
    flex-direction: column;
  }
}
.impact-top{
  width: 60%;
  display: flex;
  gap: 30px;
  @media (max-width: 1100px) {
    width: 100%;
  }
}
.impact-top .impact{
  width: 33.333%;
  @media (max-width: 550px) {
   width: 100%;
  }
}
.impact-top .impact img{
  width: 100%;
}
.impact-bottom{
  width: 40%;
  display: flex;
  gap: 30px;
  @media (max-width: 1100px) {
    width: 95%;
  }
}
.impact-bottom .impact{
  width: 46%;
  @media (max-width: 1100px) {
    width: 33%;
  }
  @media (max-width: 550px) {
    width: 100%;
   }
}
.impact-bottom .impact img{
  width: 100%;
}
.impact {
  width: 20%;
  @media (max-width: 550px) {
    width: 80%;
    margin: auto;
  }
}
.impact h3 {
  font-weight: 600;
  font-size: 20px;
  color: rgb(3, 110, 182);
  @media (max-width: 1024px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
  margin: 10px  0;
  //height: 65px;
  @media (max-width: 550px) {
    height: 100%;
    margin-bottom: 10px;
  }
}

.impact p {
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: #616379;
  @media (max-width: 1024px) {
    font-size: 13px;
  }
}
// .impact img {
//   width: 100%;
// }
